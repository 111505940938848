import React, { Component } from "react"
import { navigate } from 'gatsby';

class HomePage extends Component {
  componentDidMount() {
    const recentTicketToken = localStorage.getItem('recentTicket');
    if (recentTicketToken) {
        navigate(`/ticket#token=${recentTicketToken}`);
    }
  }

  render() {
    return null;
  }
}

export default HomePage
